import * as React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Header from "../components/Header/Header";
import Nav from "../components/Nav/Nav";

const AboutPage = ({ data }) => {
  let pageHeader = JSON.parse(data.contentfulPage.pageHeading.raw),
      pageContent = JSON.parse(data.contentfulPage.pageContent.raw);
  console.log(pageHeader);
  return (
    <>
      <Header/>
      <style>
        {`
          html, 
          body {
            overflow: hidden;
          }
        `}
      </style>
      <div className="wrapper about-wrapper">
        <Nav />
        <div className="about-page-container splitpage-container">
          <div className="about-page-left-side splitpage-left-side">
            {documentToReactComponents(pageHeader)}
          </div>
          <div className="about-page-right-side splitpage-right-side">
            {documentToReactComponents(pageContent)}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
{
  contentfulPage(pageTitle: {eq: "About"}) {
    pageHeading {
      raw
    }
    pageContent {
      raw
    }
  }
}
`

export default AboutPage;